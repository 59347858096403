.table thead tr th {
	border-bottom: 2px solid #e7ecf1 !important;
	font-weight: 500;
}

.table> :not(:first-child) {
	border-top: 0 !important;
}

.form-control[readonly] {
	background-color: #eff2f5;
}

.col-form-label {
	font-weight: 400;
}

.svg-icon.svg-icon-lg svg {
	height: 2.75rem !important;
	width: 2.75rem !important;
}

.react-select-4-listbox {
	width: 300px !important;
	position: absolute !important;
}

.btn-b {
	background-color: #ff00ff !important;
	color: #fff;
}

.btn-b {
	background-color: #ff00ff !important;
	color: #fff;
}

.btn-b:hover {
	background-color: #d9027d !important;
	color: #fff;
	transition: 0.7s;
}

.card .card-header .card-title {
	color: #ec008c !important;
}

.page-link:hover {
	color: #ec008c !important;

}

.page-item.active .page-link {
	background-color: #ec008c !important;
	color: #fff !important;
}


@media (min-width: 992px) {
	.header-fixed .header {
		background-color: #1e1e2d !important;
		/* background-color: #1e1e2d;
		box-shadow: 0px 0px 40px 0px rgba(82, 63, 105, 0.1); */
	}
}

.aside.aside-dark .aside-logo {
	background-color: #1e1e2d !important;
}

.svg-icon.svg-icon-b svg [fill]:not(.permanent):not(g) {
	fill: #ec008c !important;
}

/*timeline style */
.timeline.timeline-3 .timeline-items {
	margin: 0;
	padding: 0;
}

.timeline.timeline-3 .timeline-items .timeline-item {
	margin-left: 25px;
	border-left: 2px solid #e7ecf1;
	padding: 0 0 20px 50px;
	position: relative;
}

.timeline.timeline-3 .timeline-items .timeline-item:last-child {
	border-left-color: transparent;
	padding-bottom: 0;
}

.timeline.timeline-3 .timeline-items .timeline-item .timeline-media {
	position: absolute;
	top: 0;
	left: -26px;
	border: 2px solid #e7ecf1;
	border-radius: 100%;
	width: 50px;
	height: 50px;
	display: flex;
	justify-content: center;
	align-items: center;
	background-color: #f5f6fa;
	line-height: 0;
}

/* 
.btn-success {
	background-color: #ec008c !important;
	border-color: #ec008c !important;
	color: #fff;
} */

.btn-check:checked+.btn.btn-success,
.btn-check:active+.btn.btn-success,
.btn.btn-success:focus:not(.btn-active),
.btn.btn-success:hover:not(.btn-active),
.btn.btn-success:active:not(.btn-active),
.btn.btn-success.active,
.btn.btn-success.show,
.show>.btn.btn-success {
	background-color: #d9027d !important;
	color: #fff;
	transition: 0.7s;
}

.swal2-icon.swal2-success [class^=swal2-success-line] {
	background-color: #ec008c !important;
}

.swal2-icon.swal2-success .swal2-success-ring {
	border-color: #ec008c !important;
}

.swal2-styled.swal2-confirm {
	background-color: #ec008c !important;
}

.swal2-icon.swal2-warning {
	border-color: #84329b !important;
	color: #84329b !important;
}

.swal2-icon.swal2-error [class^=swal2-x-mark-line] {
	background-color: #f1416c !important;
}

.swal2-icon.swal2-error {
	border-color: #f1416c !important;
	color: #f1416c !important;
}

.card-label {
	color: #666 !important;
}

.dashboard-click {
	cursor: pointer;
}

.dashboard-click:hover {
	background-color: #f1faff !important;
}

.form-control {
	line-height: 1.1;
}

.svg-icon.svg-icon-2 svg {
	height: 2.5rem !important;
	width: 2.5rem !important;
}

.menu-item .menu-link .menu-icon {
	width: 2.5rem !important;
}

.header-fixed .header {
	height: 45px !important;
}

@media (min-width: 992px) {
	.content {
		margin-top: -85px;
	}
}

.form-control-sm {
	min-height: calc(0.75em + 2px);
}

.table-sm> :not(caption)>*>* {
	padding: 0.25rem 0.25rem;
}

.data-table td,th{
	padding: 0.25rem 0.25rem !important;
	font-size: .95rem!important;
}



.aside-dark .menu .menu-item .menu-link .menu-icon .svg-icon svg [fill]:not(.permanent):not(g) {
	fill: #ffffff;
}

.btn:not(.btn-outline):not(.btn-dashed):not(.border-hover):not(.border-active):not(.btn-flush):not(.btn-icon).btn-sm,
.btn-group-sm>.btn:not(.btn-outline):not(.btn-dashed):not(.border-hover):not(.border-active):not(.btn-flush):not(.btn-icon) {
	padding: calc(0.25rem + 1px) calc(0.45rem + 1px);
}

.btn-sm,
.btn-group-sm>.btn {
	padding: 0.55rem 1.25rem;
	font-size: 0.825rem;
	border-radius: 0.475rem;
}

.badge-cyan {
    color: var(--bs-white);
    background-color: var(--bs-cyan);
}

/* .css-b62m3t-container{
	z-index: 99999 !important;
} */

.card-lead:hover, .card-activity:hover{
	cursor: pointer;
	background-color: beige;
}

.rct-collapse, .rct-checkbox, .rct-node-icon {
    padding: 0 2px;
}

/* .table-sticky thead th {
    position: sticky;
    top: 0;
    z-index: 0;
    background-color: white;
} */

.table-sticky thead th {
	position: sticky;
	top: 0;
	background-color: white; /* Or any color you want for the header background */
	z-index: 0;
  }
  
  .table-sticky thead tr:nth-child(2) th {
	top: 20px; /* Adjust this based on the height of your first header row */
	z-index: 1;
  }

  .trial-balance .sticky-bottom td {
	padding: 0.2rem 0.20rem;
  }

  .trial-balance-container {
		max-height: calc(100vh - 260px); 
		overflow-y: auto;
	}

	.sticky-top {
		position: sticky;
		top: 0;
		z-index: 1;
	}

	.sticky-bottom {
		position: sticky;
		bottom: 0;
		z-index: 1;
	}

	.trial-balance {
		margin-top: 20px; 
	}
